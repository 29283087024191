const classNames = [

	'careerum-en-header',
    'careerum-en-header-tablet',
    'careerum-en-header-mobile',

	'careerum-club40-main',
    'careerum-club40-main-tablet',
    'careerum-club40-main-mobile',

	'careerum-club40-about',
    'careerum-club40-about-tablet',
    'careerum-club40-about-mobile',

	'careerum-club40-goals',
    'careerum-club40-goals-tablet',
    'careerum-club40-goals-mobile',

	'careerum-club40-clients',
    'careerum-club40-clients-tablet',
    'careerum-club40-clients-mobile',

	'careerum-club40-mission',
    'careerum-club40-mission-tablet',
    'careerum-club40-mission-mobile',

	'careerum-club40-author',
    'careerum-club40-author-tablet',
    'careerum-club40-author-mobile',

	'careerum-club40-time',
    'careerum-club40-time-tablet',
    'careerum-club40-time-mobile',

	'careerum-club40-tariffs',
    'careerum-club40-tariffs-tablet',
    'careerum-club40-tariffs-mobile',

	'careerum-club40-support',
    'careerum-club40-support-tablet',
    'careerum-club40-support-mobile',

	'careerum-club40-form',
    'careerum-club40-form-tablet',
    'careerum-club40-form-mobile',

	'careerum-club40-banner1',
    'careerum-club40-banner1-tablet',
    'careerum-club40-banner1-mobile',

	'careerum-course40-main',
    'careerum-course40-main-tablet',
    'careerum-course40-main-mobile',

	'careerum-course40-about',
    'careerum-course40-about-tablet',
    'careerum-course40-about-mobile',

	'careerum-course40-results',
    'careerum-course40-results-tablet',
    'careerum-course40-results-mobile',

	'careerum-course40-banner1',
    'careerum-course40-banner1-tablet',
    'careerum-course40-banner1-mobile',

	'careerum-course40-banner2',

	'careerum-course40-tariffs',
    'careerum-course40-tariffs-tablet',
    'careerum-course40-tariffs-mobile',


    'careerum-popup',
    'careerum-club40-popup-pay',
    'careerum-club40-popup-lk',
    'careerum-club40-popup-subscribe',
    'careerum-club40-popup-thanks',
	'no-cabinet',
	'has-cabinet',
    'club40-time-item',
    'club40-tariffs-item',
    'course40-tariffs-item',
    'club40-goals-form'

];

export default classNames;